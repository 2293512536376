.custom-select div{
  z-index:10;
  border: none!important;
  box-shadow: none;
  background: none;
}

.custom-select div[role="option"]{
  color:#000;
  background: #fafafa;
}

.custom-select div[role="option"]:hover{
  cursor: pointer;
  color: #000;
}

.custom-select{
  border-bottom: 1px solid black!important;
  cursor: pointer;
}