.base {
    height: 100%;
    background-color: #000;
    background-image: linear-gradient(125.49deg, #000 -17.33%, #333 100%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .base .text {
    font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif;
  }
  .base .frame {
    position: relative;
    width: 969px;
    height: 488px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 48px;
  }
  @media (max-width: 799px) {
    .base .frame {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: 5% auto auto auto;
      height: 440px;
      width: 90%;
      padding: 20px 24px 0px 24px;
      background-color: #fff;
      -webkit-box-sizing: border-box;
      /* Safari/Chrome, other WebKit */
      -moz-box-sizing: border-box;
      /* Firefox, other Gecko */
      text-align: center;
    }
  }
  .base .guide {
    display: block;
    height: 100%;
    width: 320px;
    min-width: 250px;
    opacity: 0.9;
  }
  .base .guide img {
    max-width: 230px;
  }
  .base .guide h1 {
    padding-top: 44px;
    font-size: 36px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.11;
    color: #4f4f4f;
  }
  @media (max-width: 799px) {
    .base .guide h1 {
      padding-top: 25px;
      font-size: 22px;
      padding-bottom: 0;
      margin: 0;
    }
  }
  .base .guide h2 {
    padding-top: 65px;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    color: #4f4f4f;
  }
  @media (max-width: 799px) {
    .base .guide h2 {
      padding-top: 25px;
      margin: 0;
    }
  }
  @media (max-width: 799px) {
    .base .guide {
      width: 100%;
    }
  }
  .base .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #f8f8f8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 56px 48px;
    z-index: 1;
    position: relative;
    right: 0;
    width: 440px;
    height: 568px;
  }
  .base .card .image-holder {
    width: 112px;
    height: 112px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  @media (max-width: 740px) {
    .base .card .image-holder {
      display: none;
    }
  }
  .base .card .back {
    cursor: pointer;
    position: absolute;
    left: 24px;
    top: 36px;
    width: 10px;
    height: 16px;
  }
  @media (max-width: 740px) {
    .base .card .back {
      left: 14px;
      top: 27px;
    }
  }
  .base .card button {
    margin-bottom: 25px;
  }
  .base .card .checkbox .check {
    background-color: #000 !important;
  }
  .base .card .btn-full {
    margin-bottom: 25px;
  }
  .base .card .btn-full:enabled {
    background-color: #000 !important;
  }
  .base .card form {
    width: 100%;
  }
  @media (max-width: 799px) {
    .base .card {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto auto 5% auto;
      height: 360px;
      width: 80%;
      padding: 20px 24px 0px 24px;
      background-color: #f8f8f8;
      -webkit-box-sizing: border-box;
      /* Safari/Chrome, other WebKit */
      -moz-box-sizing: border-box;
      /* Firefox, other Gecko */
    }
  }
  